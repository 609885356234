import React from "react";

const ClientGroups = (props) => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-4 d-flex align-items-center">
          <h3 className="title-4">{props.category}</h3>
        </div>
        <div className="col-lg-8">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="img-grid">
                <div className="card">
                  <a
                    href={props.link1}
                    target="_blank"
                  >
                    <img
                      className="img-partner"
                      alt="Partners"
                      src={props.image1}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="img-grid">
                <div className="card">
                  <a
                    href={props.link2}
                    target="_blank"
                  >
                    <img
                      className="img-partner"
                      alt="Partners"
                      src={props.image2}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="img-grid">
                <div className="card">
                  <a
                    href={props.link3}
                    target="_blank"
                  >
                    <img
                      className="img-partner"
                      alt="Partners"
                      src={props.image3}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientGroups;
