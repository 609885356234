import React from "react";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Banner from "../../images/about-banner.jpg";

import Services from "../../images/user-centric-optimized.jpg";
import IntroBanner from "../../components/IntroBannerRevamp";
import ClientGroups from "../../components/ClientGroups";
import ServiceCard from "../../components/ServiceCard";
import Partners from "../../components/indexPage/Partners";
import TrustedClients from "../../components/TrustedClients";
import BookConsultation from "../../components/BookConsultation";
import WhatOurCustomerSays from "../../components/homePage/WhatOurCustomerSays";

// logos
import A1 from "../../images/home-page/logos-png/A1.png";
import Beforepay from "../../images/home-page/logos-png/Beforepay.png";
import effi from "../../images/home-page/logos-png/effi.png";

const Clients = (props) => {
  const tabData = [
    { label: "Energy", content: <TrustedClients /> },
    { label: "FinTech", content: <TrustedClients /> },
    { label: "Banking", content: <TrustedClients /> },
    { label: "Telco", content: <TrustedClients /> },
    { label: "Health", content: <TrustedClients /> },
  ];
  return (
    <Layout bodyClass="wr-clients">
      <Seo
        title="Our Clients"
        description="iTelaSoft is an Australian-owned company providing IT services and
              software product development.. We specialise in strategy
              consultation, design, development and management of products. We
              help entrepreneurs transform ground breaking ideas into successful
              solutions."
      />
      <IntroBanner
        title="Our Clients"
        subtitle="We work with companies large and small to create innovative technological solutions."
        image={Banner}
      />
      <section className="light-grey-section mb-6">
        <div className="container">
          <ClientGroups
            category="Start up / Scale up"
            link1="https://a1spareparts.com.au/"
            image1={A1}
            link2="https://www.beforepay.com.au/"
            image2={Beforepay}
            link3="https://www.effi.com.au/"
            image3={effi}
          />
          <ClientGroups
            category="SME’s"
            link1="https://a1spareparts.com.au/"
            image1={A1}
            link2="https://www.beforepay.com.au/"
            image2={Beforepay}
            link3="https://www.effi.com.au/"
            image3={effi}
          />
          <ClientGroups
            category="Enterprise"
            link1="https://a1spareparts.com.au/"
            image1={A1}
            link2="https://www.beforepay.com.au/"
            image2={Beforepay}
            link3="https://www.effi.com.au/"
            image3={effi}
          />
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row pb-6">
            <div className="col-12">
              <h2 className="title-1 pb-3">Services</h2>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ServiceCard
                image={Services}
                title="Strategy & Design"
                description="We take you from ideation to delivery and beyond"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ServiceCard
                image={Services}
                title="Strategy & Design"
                description="We take you from ideation to delivery and beyond"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ServiceCard
                image={Services}
                title="Strategy & Design"
                description="We take you from ideation to delivery and beyond"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="wr-industries">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="title-1">Industries</h2>
            </div>
          </div>
            <Partners />
        </div>
      </section>
      <section>
        <div className="container pb-6">
          <WhatOurCustomerSays />
        </div>
      </section>
      <section className="grey-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="title-1 pb-3">Case study</h2>
              <p>
                We have worked with a variety of clients to create innovative
                solutions. Read our case studies to find out more.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row py-6">
            <div className="col-12">
              <h2 className="title-1 pb-6">Book a free consultation</h2>
            </div>
            <div className="col-12">
              <BookConsultation image={Banner} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Clients;
