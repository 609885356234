import React from "react";
import ContactForm from "./ContactForm";

const GetInTouch = (props) => {
  return (
    <div className="row">
      <div className="col-md-6 pr-md-5 pb-5 pb-md-0">
        <img src={props.image} alt="About Us" className="img-fluid" />
      </div>
      <div className="col-md-6">
        <ContactForm />
      </div>
    </div>
  );
};

export default GetInTouch;
