import React from "react";
import { Form, Field } from "react-final-form";
import ReCAPTCHA from "react-google-recaptcha";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
  }

  state = {
    formSuccess: false,
    formFail: false,
    isDisabled: false,
    recaptchaToken: null,
    recaptchaError: false
  };

  onSubmit = async (values, form) => {
    console.log(JSON.stringify(values));
    if (this.state.recaptchaToken === null) {
      this.setState({ recaptchaError: true });
      return
    }
    else this.setState({ recaptchaError: false });

    this.setState({ isDisabled: true, formFail: false, formSuccess: false });
    // window.alert(JSON.stringify(values, 0, 2));
    return fetch(
      "https://j8nf5s6dr7.execute-api.ap-southeast-2.amazonaws.com/contact-us",
      {
        mode: "no-cors",
        method: "POST",
        body: JSON.stringify(values),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => {
        form.pauseValidation();
        form.restart();
        this.setState({ formSuccess: true, isDisabled: false, formFail: false });
        setTimeout(() => this.setState({ formSuccess: false }), 10000)
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ formFail: true, isDisabled: false, formSuccess: false });
        setTimeout(() => this.setState({ formFail: false }), 10000);
      });
  };

  onReCAPTCHAChange = (token) => {
    this.setState({ recaptchaToken: token });
  };

  render() {
    return (
      <div>
      <Form
        onSubmit={this.onSubmit}
        validate={(values) => {
          const errors = {};
          if (!values.FirstName) {
            errors.FirstName = "Required";
          } 
          if (!values.LastName) {
            errors.LastName = "Required";
          }
          if (!values.Email) {
            errors.Email = "Required";
          }
          // else if () {
          //     errors.from = "Valid Email Required";
          // }
          if (!values.CompanyName) {
            errors.CompanyName = "Required";
          }
          if (!values.Message) {
            errors.Message = "Required";
          }
          return errors;
        }}
        initialValues={{ to: "info@itelasoft.com" }}
        render={({
          handleSubmit,
          reset,
          form,
          submitting,
          pristine,
          values
        }) => (
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="wr-form mb-xs-5">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <Field name="FirstName">
                      {({ input, meta }) => (
                        <div>
                          <label className="control-label">
                            <div>First Name</div>
                          </label>
                          <input {...input} type="text" className="form-control" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <Field name="LastName">
                      {({ input, meta }) => (
                        <div>
                          <label className="control-label">
                            <div>Last Name</div>
                          </label>
                          <input {...input} type="text" className="form-control" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              
              
              <div className="form-group">
                <div className="form-group">
                  <Field name="Email">
                    {({ input, meta }) => (
                      <div>
                        <label className="control-label">
                          <div>EMAIL</div>
                        </label>
                        <input
                          {...input}
                          type="email"
                          className="form-control"
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div className="form-group">
                <Field name="CompanyName">
                  {({ input, meta }) => (
                    <div>
                      <label className="control-label">
                        <div>Company Name</div>
                      </label>
                      <input {...input} type="text" className="form-control" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <Field name="Phone">
                  {({ input, meta }) => (
                    <div>
                      <label className="control-label">
                        <div>Phone (optional)</div>
                      </label>
                      <input {...input} type="text" className="form-control" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <Field name="Message">
                  {({ input, meta }) => (
                    <div>
                      <label className="control-label">
                        <div>Message</div>
                      </label>
                      <textarea {...input} className="form-control" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                <Field name="to">
                  {({ input, meta }) => (
                    <div>
                      <input {...input} type="hidden" />
                    </div>
                  )}
                </Field>
                {/* <Field name="message" className="form-control" component="textarea" placeholder="Message" /> */}
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  onChange={this.onReCAPTCHAChange}
                />
                {this.state.recaptchaError && (
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#dd0000",
                      fontWeight: 600
                    }}
                  >
                    {"Required"}
                  </span>
                )}
              </div>
              <div className="submit-button buttons text-center text-md-left">
                <button
                  className={
                    "btn btn-primary spinner-btn" +
                    (this.state.isDisabled ? " btn-disabled" : "")
                  }
                  type="submit"
                  disabled={this.state.isDisabled}
                  onClick={() => form.resumeValidation()}
                >
                  Send Message
                  {this.state.isDisabled && (
                    <div className="form-spinner-wrapper">
                      <span class="form-spinner loader"></span>
                    </div>
                  )}
                </button>
              </div>
            </div>
            <div
              className={
                this.state.formSuccess
                  ? "form-msg success show"
                  : "form-msg success d-none"
              }
            >
              <span>
                Thanks for your message! <br />{" "}
                <span style={{ fontWeight: 400, padding: 0 }}>
                  We'll be in touch with you shortly.
                </span>
              </span>
            </div>
            <div
              className={
                this.state.formFail ? "form-msg fail show" : "form-msg fail d-none"
              }
            >
              <span>
                There was a problem sending your message. Please try again.
              </span>
            </div>
          </form>
        )}
      />
      </div>
    );
  }
}
