import React from "react";

import ErajRatnayake from "../../images/Clients/ErajRatnayake.jpg";
import BenTaylor from "../../images/Clients/BenTaylor.jpg";
import EmilioGonzalez from "../../images/Clients/EmilioGonzalez.jpg";
import MandeepSodhi from "../../images/Clients/MandeepSodhi.jpg";
import MustafaBensan from "../../images/Clients/MustafaBensan.jpg";
import StefanZurflueh from "../../images/Clients/StefanZurflueh.jpg";
import RobbieBrender from "../../images/Clients/RobbieBrender.jpg";
import NikkiTaylor from "../../images/Clients/NikkiTaylor.jpg";
import MattHinds from "../../images/Clients/MattHinds.jpg";

import SvgIconsSet from "../SvgIconsSet";
import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));
const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplay: true,
  autoplayHoverPause: true,
  margin: 10,
  autoplayTimeout: 25000,
  autoplaySpeed: 2000,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};
const customerDetail = [
  {
    src: ErajRatnayake,
    alt: "Eraj Ratnayake",
    customerName: "Eraj Ratnayake",
    customerCountry: "Lilardia Capital",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“I’ve been working with iTelaSoft on a number of projects. Initially I engaged with iTelaSoft UX staff to design the product interface, and then with the technology staff to design the payment flows, the data standards, and the workflow of the overall platform. In all these aspects my experience with iTelaSoft has been a positive one, which has culminated in the successful launch of our product.”",
  },
  {
    src: MustafaBensan,
    alt: "Mustafa Bensan",
    customerName: "Mustafa Bensan",
    customerCountry: "Data Glider",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“As the founder of a small software startup, I was looking for a partner to complement our team and help us with our product. That’s where iTelaSoft came in and shone. They were really fun to work with and collaborate with. The team also provided good structure to the project so we could achieve our milestone in a timely manner. We're really happy with the outcome and looking forward to continuing our partnership.”",
  },
  {
    src: BenTaylor,
    alt: "Ben Taylor",
    customerName: "Ben Taylor",
    customerCountry: "Oxygen Broking Services",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“We've been using iTelasoft for the last two years and they've truly become a trusted partner of ours and are now doing almost all of our I.T. development and integration work. In my 25 years of experience in financial services and digital automation, I rate iTelaSoft as by far the best integration and software development partner I've ever used in my working career.”",
  },
  {
    src: StefanZurflueh,
    alt: "Stefan Zurflueh",
    customerName: "Stefan Zurflueh",
    customerCountry: "Stride Equity",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“iTelasoft has consistently proven itself to be a strong and reliable partner. During the launch of our platform, we identified several opportunities to improve the UX. We requested changes, and iTelasoft implemented them without any delays or impact on the user experience. iTelasoft has demonstrated multiple times its ability to meet ambitious timeline, and we value what the team brings in terms of new ideas. We’re excited to continue working with iTelasoft on enhancing our digital investment platform.”",
  },
  {
    src: EmilioGonzalez,
    alt: "Emilio Gonzalez",
    customerName: "Emilio Gonzalez",
    customerCountry: "Symbox",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“When Symbox was acquired by 10Pearls, it brought many challenges. I’d like to praise the iTelaSoft team on how they supported us every step of the way in navigating the legal and compliance changes we needed to manage. From knowledge transfer, customer retention and service continuity, iTelaSoft were the steady ship we could rely on to ensure delivery never stopped. iTelaSoft is held in my highest regard as a partner, and I would not hesitate to work with them again.”",
  },
  {
    src: MandeepSodhi,
    alt: "Mandeep Sodhi",
    customerName: "Mandeep Sodhi",
    customerCountry: "Effi",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“I have been working with iTelaSoft since Jan 2020, and they have added value to our mortgage broker platform by enhancing the platform’s features and allocating a dedicated team. They are also providing project coordination as a service to us, which is one of the reasons for this engagement to be successful, thanks to their commitment and corporative approach. As a client, we are very much satisfied with their work and will definitely recommend them for a similar engagement”",
  },
  {
    src: NikkiTaylor,
    alt: "Nikki Taylor",
    customerName: "Nikki Taylor",
    customerCountry: "Prodapt Consulting",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“Management always professional, easy to work with and responsive in support of solutions and queries. Team always worked in most efficient manner and to time. Highly recommend iTelaSoft services to others.”",
  },
  {
    src: ErajRatnayake,
    alt: "Eraj Ratnayake",
    customerName: "Eraj Ratnayake",
    customerCountry: "SEA Easy Capital",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“Itelasoft employees that I engaged with were all very high caliber, who went over and beyond their remits to ensure SEA Easy Capital platform was commercially released. Always very helpful, diligent and have made themselves available readily. They have shown flexibility in their approach to engaging with SEA Easy Capital and take a very holistic view of client management. An excellent people organization to deal with, would highly recommend them.”",
  },
  {
    src: RobbieBrender,
    alt: "Robbie Brender",
    customerName: "Robbie Brender",
    customerCountry: "Possibl",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“The iTelaSoft team are the team we depend on, when we need to implement complex features or functionality in a timely manner, with certainty that it will be done right, the first time, on time and budget. Being able to leverage off a knowledgeable team like the iTelaSoft team has enabled Possibl to grow and rapidly deploy features to our product.”",
  },
  {
    src: RobbieBrender,
    alt: "Robbie Brender",
    customerName: "Robbie Brender",
    customerCountry: "Projuno",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“The iTelaSoft team has provided an enormous amount of value to the Projuno product. Be it through specific feature implementation or integration or for a dedicated resource who works within our team, we know that we can rely on iTelaSoft for whatever development needs we may have, to provide quality development work and resourcing.”",
  },
  {
    src: MattHinds,
    alt: "Matt Hinds",
    customerName: "Matt Hinds",
    customerCountry: "Sauce AI",
    linkDinhref: "https://www.itelasoft.com.au/",
    description:
      "“I've worked with iTelaSoft on multiple projects now (both engineering and design) including a full web app build and new website. Always very impressed with the teams quality, speed of execution and communication. Every project has exceeded expectations.”",
  },
  
];


const WhatOurCustomerSays = () => {
  return (
    <div className="What-our-customer-says">
      <div className="title-1 mb-2">What our customers say</div>
      <div className="What-our-customer-says-cardSet">
        <OwlCarousel className="owl-theme" {...options}>
          {customerDetail.map((detail) => (
          <div className="item What-our-customer-says-card">
            <div className="What-our-customer-says-card-top">
              <div className="What-our-customer-says-card-top-left">
                <div className="What-our-customer-says-card-icon">
                  <img src={detail.src} alt={detail.alt} />
                </div>
                <div className="wrap-card-What-our-customer-detail">
                  <div className="What-our-customer-says-card-name">
                    {detail.customerName}
                  </div>
                  <div className="What-our-customer-says-card-country">
                    {detail.customerCountry}
                  </div>
                </div>
              </div>
              {/* <div className="icon-linkdin">
                <a href={detail.linkDinhref}>
                  <SvgIconsSet.Linkedin />
                </a>
              </div> */}
            </div>
            <div className="section-discription">{detail.description}</div>
          </div>
        ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default WhatOurCustomerSays;
